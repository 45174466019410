import React from "react"
import styled, { css } from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import { EqualHeight, EqualHeightElement } from 'react-equal-height/clean';
import Layout from "../components/Layout"
import Seo from "../components/seo"
import TopPanel from "../components/TopPanel";
import { Section } from "../components/section";
import { Title2, TitleWrapper, SubText, Title3 } from "../components/Headings";
import { ContentWrapper } from "../components/common";
import icon01Svg from '../images/svg/finance-01.svg';
import icon02Svg from '../images/svg/finance-02.svg';
import icon03Svg from '../images/svg/finance-03.svg';
import icon04Svg from '../images/svg/finance-04.svg';
import icon05Svg from '../images/svg/finance-05.svg';
import FinanceForm from "../components/FinanceForm";

const iconStyle = css`
  fill: ${({theme}) => theme.colors.icon};
  height: 70px;
  width: auto;
  /* margin-bottom: 32px; */
  height: 100%;
  width: auto;
  /* fill: ${({theme}) => theme.colors.white}; */
`;

const Icon01 = styled(icon01Svg)`
  ${iconStyle};
`;

const Icon02 = styled(icon02Svg)`
  ${iconStyle};
`;

const Icon03 = styled(icon03Svg)`
  ${iconStyle};
`;

const Icon04 = styled(icon04Svg)`
  ${iconStyle};
`;

const Icon05 = styled(icon05Svg)`
  ${iconStyle};
`;

const PinkSection = styled(Section)`
  background-color: ${({theme}) => theme.colors.primary};

  ${TitleWrapper} {
    margin-bottom: 70px;
  }

  ${Title2}, ${Title3}, ${SubText} {
    color: ${({theme}) => theme.colors.white};
  }

  ${Title3} {
    text-align: center;
  }

  @media(min-width: 992px) {
    padding-top: 100px;
  }
`;

const IconWrapper = styled.div`
  height: 55px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const Title = styled.p`
  display: block;
  font-family: 'Oswald', Georgia, sans-serif;
  font-size: 1.375rem;
  color: ${({ theme }) => theme.colors.icon} !important;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: 'Oswald', Georgia, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  @media (min-width: 1200px) {
    font-size: 1.25rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* display: grid;
  grid-template-columns: 1fr; */
  /* row-gap: 1.5rem; */
  /* column-gap: 2.25rem; */
  text-align: center;

  p:last-child {
    color: ${({theme}) => theme.colors.white};
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 16px;
    line-height: 1.5rem;
  }

  @media (min-width: 768px) {
    /* grid-template-columns: 1fr;
    text-align: center; */
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3.75rem;
  column-gap: 2rem;
  /* margin-top: 50px; */

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const CenteredText = styled.div`
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1200px;
    p {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
`;

function FinancePage () {
  return (
    <Layout>
      <Seo title="Financování" />
      <TopPanel
        title="Zajistěte si výhodné financování"
        text="Jsme tu, abychom vám pomohli najít optimální řešení a&nbsp;zajistili financování vašeho budoucího bydlení."
        btnLabel="Spočítat hypotéku"
        anchorLink="#hypotecni-kalkulacka"
        >
        <StaticImage src="../images/panel-03-n.jpg" className="panel-image" placeholder="blurred" alt="Foto" />
      </TopPanel>
      <Section>
         <ContentWrapper>
           <TitleWrapper>
             <Title2>Financování nového bydlení krok za krokem</Title2>
           </TitleWrapper>
           <div className="row">
            <div className="col">
              <CenteredText>
                <p>Naši specialisté jsou tu ale od toho, aby vám pomohli. Společně probereme vaše finanční možnosti a&nbsp;pomůžeme vám najít cestu k&nbsp;vysněnému bydlení.</p>
              </CenteredText>
             </div>
           </div>
         </ContentWrapper>
      </Section>
      {/* <Grid> */}
        <PinkSection>
          <ContentWrapper>
            <EqualHeight>
          {/* <Title3>Hypoteční úvěr</Title3> */}
              <IconGrid>
                <Item>
                  <IconWrapper>
                    <Icon01 />
                  </IconWrapper>
                  <div>
                    <EqualHeightElement name="title">
                      <Title>1/&nbsp;Výběr Vašeho nového bytu</Title>
                    </EqualHeightElement>
                    <p>Projděte si naši nabídku bytů a&nbsp;vyberte si svůj budoucí domov. Patro, velikost, balkon nebo terasa? Na všem záleží.</p>
                  </div>
                </Item>
                <Item>
                  <IconWrapper>
                    <Icon02 />
                  </IconWrapper>
                  <div>
                    <EqualHeightElement name="title">
                      <Title>2/&nbsp;Rezervace bytu a&nbsp;smluvní dokumentace</Title>
                    </EqualHeightElement>
                    <p>Vybrali jste? Výborně! Kontaktujte nás a&nbsp;sdělte, pro který byt jste se rozhodli. My provedeme rezervaci a&nbsp;přichystáme potřebné dokumenty.</p>
                  </div>
                </Item>
                <Item>
                  <IconWrapper>
                    <Icon03 />
                  </IconWrapper>
                  <div>
                    <EqualHeightElement name="title">
                      <Title>3/&nbsp;Výběr financující banky</Title>
                    </EqualHeightElement>
                    <p>Na hledání vhodné hypotéky nemusíte být sami. Porovnáme nabídky bankovních institucí a&nbsp;společně najdeme tu, která vám sedne.</p>
                  </div>
                </Item>
                <Item>
                  <IconWrapper>
                    <Icon04 />
                  </IconWrapper>
                  <div>
                    <EqualHeightElement name="title">
                      <Title>4/&nbsp;Kupní smlouva a&nbsp;převzetí bytu</Title>
                    </EqualHeightElement>
                    <p>Veškerou dokumentaci, včetně kupní smlouvy, připravíme. Na nové bydlení se můžete těšit na konci roku&nbsp;2025.</p>
                  </div>
                </Item>
                <Item>
                  <IconWrapper>
                    <Icon05 />
                  </IconWrapper>
                  <div>
                    <EqualHeightElement name="title">
                      <Title>5/&nbsp;Přepis listu vlastnictví v&nbsp;katastru nemovitostí</Title>
                    </EqualHeightElement>
                    <p>Převzali jste byt. Teď už zbývá jen přepsat vás v&nbsp;katastru nemovitostí jako vlastníka. I&nbsp;od toho jsme tu my.</p>
                  </div>
                </Item>
              </IconGrid>
            </EqualHeight>
          </ContentWrapper>
        </PinkSection>
        {/* <MutedPinkSection>
          <HalfContentWrapper>
          <Title3>Družstevní financování</Title3>
              <IconGrid>
                <Item>
                  <IconWrap>
                    <IconWrapper>
                      <Icon01 />
                    </IconWrapper>
                  </IconWrap>
                  <div>
                    <Title>1/&nbsp;Vyberte si byt podle svých představ</Title>
                    <p>Váš budoucí domov najdete na stránce <Link to={APARTMENT_SELECTION}>VÝBĚR BYTU</Link>. Který čeká právě na vás? Menší, větší, s&nbsp;balkonem? Dejte si čas a&nbsp;vše pořádně promyslete.</p>
                  </div>
                </Item>
                <Item>
                  <IconWrap>
                    <IconWrapper>
                      <Icon02 />
                    </IconWrapper>
                  </IconWrap>
                  <div>
                    <Title>2/&nbsp;Rychlé schválení a&nbsp;smluvní dokumentace</Title>
                    <p>Není čas ztrácet čas. Uděláme vše proto, aby proces běžel rychle. Zařídíme vše důležité, stačí nám dát vědět své preference. K&nbsp;novému bytu zbývá jen kousek!</p>
                  </div>
                </Item>
                <Item>
                  <IconWrap>
                    <IconWrapper>
                      <Icon04 />
                    </IconWrapper>
                  </IconWrap>
                  <div>
                    <Title>3/&nbsp;Placení nájmu</Title>
                    <p>Nemovitost začínáte pravidelně splácet ve formě měsíčního nájmu. Bez prokázání příjmů a&nbsp;schvalování bankou se rovnou stáváte vlastníky družstevního podílu a&nbsp;byt splácíte.</p>
                  </div>
                </Item>
                <Item>
                  <IconWrap>
                    <IconWrapper>
                      <Icon06 />
                    </IconWrapper>
                  </IconWrap>
                  <div>
                    <Title>4/&nbsp;Spokojené bydlení</Title>
                    <p>Když je všechna byrokracie vyřízená a&nbsp;vaše platby odchází, užíváte si svého nového bytu. Že rezidence Třešňovka má co nabídnout, to už víte!</p>
                  </div>
                </Item>

              <Item>
                <IconWrapper>
                  <Icon05 />
                </IconWrapper>
                <div>
                  <Title>5/&nbsp;Převod nemovitosti do osobního vlastnictví</Title>
                  <p>Po splacení celé částky přejde nemovitost do vašeho osobního vlastnictví. A&nbsp;když budete chtít být vlastníkem dříve? Bude to možné dle platných pravidel družstva.</p>
                </div>
              </Item>
              </IconGrid>
          </HalfContentWrapper>
        </MutedPinkSection> */}
      {/* </Grid> */}
      <Section id="hypotecni-kalkulacka">
         <ContentWrapper>
           <TitleWrapper>
             <Title2>Hypoteční kalkulačka</Title2>
           </TitleWrapper>
           <div className="row">
            <div className="col">
              <CenteredText>
                <p>Můžeme to rychle orientačně spočítat</p>
              </CenteredText>
             </div>
           </div>
           <FinanceForm />
         </ContentWrapper>
      </Section>
      {/* <FinanceExamples /> */}
    </Layout>
  );
}

export default FinancePage;
