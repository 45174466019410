import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { setIn } from 'final-form';
import { Form, Field } from 'react-final-form';

const PERIOD_VALUES = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];

const validationSchema = yup.object({
  price: yup.number().typeError('Zadejte číselnou hodnotu').nullable(true).required('Pole je povinné'),
  period: yup.number().typeError('Zadejte hodnotu').required('Zadejte hodnotu').min(5, 'Zadejte hodnotu'),
});

const validateFormValues = (schema) => async (values) => {
	if (typeof schema === 'function') {
		schema = schema();
	}
	try {
		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
		}, {});

		return errors;
	}
};

const validate = validateFormValues(validationSchema);


const FormWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 24px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  column-gap: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: 3fr 2fr auto;
  }
`;

const Input = styled.input`
  height: 50px;
  padding: 0 0.75rem;
  border: 0;
  width: 100%;
  background-color: ${({theme}) => theme.colors.primary_light};
  color: #404040;
  border: 2px solid ${({theme, hasError}) => hasError ? theme.colors.white : 'transparent'};

  &:focus {
    outline: 0;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #404040;
    opacity: 1; /* Firefox */
    text-align: left;
  }

  @media(min-width: 992px) {
    height: 60px;
    padding: 0 1.25rem;
    padding-right: 60px;
    text-align: right;
  }
`;

const Select = styled.select`
  height: 50px;
  padding: 0 0.75rem;
  border: 0;
  width: 100%;
  background-color: ${({theme}) => theme.colors.primary_light};
  color: #404040;
  border: 2px solid ${({theme, hasError}) => hasError ? theme.colors.white : 'transparent'};

  /* option {
    text-align: right;
  } */


  &:focus {
    outline: 0;
    padding: 0 0.75rem;
  }

  @media(min-width: 992px) {
    height: 60px;
    padding: 0 1.25rem;
  }
`;

const InputWrapper = styled.div`
  text-align: right;
  font-size: 1.125rem;
  font-weight: 600;
  position: relative;
`;

const ButtonWrapper = styled.div`

  button {
    font-family: 'Oswald', Georgia, sans-serif;
  font-size: 1.25rem;
  font-weight: 200;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.primary};
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.625rem 1.5rem;
  border: 0;
  height: 60px;
  width: 100%;

    &:disabled {
      background-color: ${({theme}) => theme.colors.medium_grey};
      color: ${({theme}) => theme.colors.btn_disabled_text};
      cursor: not-allowed;
    }

    &:focus {
      border: 0;
      outline: 0;
    }

    @media (min-width: 1200px) {
      font-size: 1.5rem;
      width: auto;
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.error};
  /* padding-left: 0.75rem; */
  margin-bottom: 0;
  position: absolute;
  bottom: -20px;
  left: 1.25rem;
`;

const Postfix = styled.span`
  position: absolute;
  font-weight: normal;
  right: 1.25rem;
  top: 17px;
`;

const ResultMessage = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.125rem;

  @media (min-width: 992px) {
    padding-left: 1.25rem;
    text-align: left;
  }
`;

const FinalResultMessage = styled(ResultMessage)`
  margin-top: 0;

  @media (min-width: 992px) {
    margin-top: 1rem;
    grid-column: span 2;
  }|
`;

function FinanceForm() {
  const [result, setResult] = useState(null);

  const onSubmit = async (values) => {

    const price = +values.price;
    const period = +values.period*12;
    const interest = 0.0489/12;

    const param = Math.pow((1+interest), period);
    const divider = param-1;
    const res = price*interest*param/divider;
    setResult(res);
  }

  return (
      <FormWrapper>
        <Form
      onSubmit={(values, form) => onSubmit(values, form)}
      initialValues={{
        price: '',
        period: '',
      }}
      validate={validate}
      render={({ handleSubmit, hasValidationErrors, submitting}) => (
        <>
          <Grid>
            <Field
              name="price"
              type="text"
            >
            {({ input, meta }) => (
              <InputWrapper>
                <Input {...input} hasError={meta.error && meta.touched} placeholder="Výše úvěru" />
                <Postfix>Kč</Postfix>
                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
              </InputWrapper>
            )}
          </Field>
          <Field
            name="period"
            type="text"
          >
          {({ input, meta }) => (
            <InputWrapper>
              <Select {...input} placeholder="sdsd">
              <option value="" disabled selected hidden>Doba splácení</option>
              {
                PERIOD_VALUES.map(item => (
                  <option key={item} value={item}>{item}&nbsp;let</option>
                ))
              }
            </Select>
            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
            </InputWrapper>
          )}
        </Field>

        <ButtonWrapper>
            <button onClick={handleSubmit} disabled={ submitting }>Spočítat splátku</button>
          </ButtonWrapper>
          <ResultMessage>Úroková sazba <strong>4,89&nbsp;%</strong></ResultMessage>
          {
         result && (
           <FinalResultMessage>Měsíční splátka: <strong>{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(Math.round(result))}</strong></FinalResultMessage>
         )
       }
          </Grid>

          </>
       )}
       />


        </FormWrapper>
  );
}

export default FinanceForm;